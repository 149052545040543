<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout class="mainfont" wrap justify-center pb-7>
      <v-flex xs11 pt-4>
        <v-layout wrap>
          <v-flex xs12>
            <v-card class="pb-4">
              <v-layout wrap justify-start>
                <v-flex xs12 pl-3 pt-3 pb-4 class="bgcustom">
                  <span style="font-size: 25px;color:white">Application Number - {{ list.applicationNo }}</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap pb-3>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px;">
                    Applicant Name
                  </span>
                  <br />
                  <span>{{ list.applicantName }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px;">
                    District
                  </span>
                  <br />
                  <span>{{ list.applicantDistrict }}</span>
                </v-flex>

                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px;">
                    Email

                  </span>
                  <br />
                  <span>{{ list.applicantEmail }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px;">
                    Mobile Number

                  </span>
                  <br />
                  <span>{{ list.applicantMobileNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3 pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Address

                  </span>
                  <br />
                  <span>{{ list.applicantAddress }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Pincode

                  </span>
                  <br />
                  <span>{{ list.applicantPinCode }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Id Type
                  </span>
                  <br />
                  <span>{{ list.idType }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Id Card No
                  </span>
                  <br />
                  <span>{{ list.idcardNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px;">
                    Nature Of Application

                  </span>
                  <br />
                  <span>{{ list.natureOfApplication }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Id Proof
                  </span>&nbsp;

                  <v-btn class="bgcustom" dark download :href="baseURL + 'file/get/' + idProof" target="_blank"><span
                      style="text-transform: none; font-family: mainfont; font-size: 12px;">View
                    </span></v-btn>
                  <br />
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Progress
                  </span>&nbsp;

                  <v-btn class="bgcustom" dark @click="$router.push('/records?id=' + id)"><span
                      style="text-transform: none; font-family: mainfont; font-size: 12px;">Progress
                    </span></v-btn>
                  <br />
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 v-if="list.animalinvolved === 'YES'">
                  <span class="subheading" style="font-size: 16px;">
                    Preshoot Order
                  </span>&nbsp;

                  <v-btn class="bgcustom" dark download :href="baseURL + 'file/get/' + Preshootfile"
                    target="_blank"><span style="text-transform: none; font-family: mainfont; font-size: 12px;">View
                    </span></v-btn>
                  <br />
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3 pt-4 v-if="list.animalinvolved === 'YES'">
                  <span class="subheading" style="font-size: 16px;">
                    Species Name

                  </span>
                  <br />
                  <span>{{ list.species }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 v-if="list.animalinvolved === 'YES'">
                  <span class="subheading" style="font-size: 16px;">
                    No Of Animals

                  </span>
                  <br />
                  <span>{{ list.noOfAnimal }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 v-if="list.animalinvolved === 'YES'">
                  <span class="subheading" style="font-size: 16px;">
                    Preshoot Number
                  </span>
                  <br />
                  <span>{{ list.preshootNumber }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 v-if="list.animalinvolved === 'YES'">
                  <span class="subheading" style="font-size: 16px;">
                    Preshoot Valid From
                  </span>
                  <br />
                  <span>{{ formatDate(list.validityfromDate) }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3 pt-4 v-if="list.animalinvolved === 'YES'">
                  <span class="subheading" style="font-size: 16px;">
                    Preshoot Valid To

                  </span>
                  <br />
                  <span>{{ formatDate(list.validitytoDate) }}</span>
                </v-flex>
                <v-flex xs12 pa-5>
                  <v-card elevation="0" style="border: 1px solid black; border-radius: 0px;">
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap class="subheading">
                          <v-flex xs2 text-center pt-2>
                            Location
                          </v-flex>
                          <v-flex xs2 text-center pt-2>
                            Film Name
                          </v-flex>
                          <v-flex xs1 text-center pt-2>
                            From Date
                          </v-flex>
                          <v-flex xs2 text-center pt-2>
                            To Date
                          </v-flex>
                          <v-flex xs2 text-center pt-2>
                            No Of Members
                          </v-flex>
                          <v-flex xs2 text-center pt-2>
                            No Of Vehicles
                          </v-flex>
                          <v-flex xs1 text-center pt-2>
                            No Of Days
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap pt-4 pb-4>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                    <v-layout pb-3 wrap v-for="(item, i) in filmShootingDetails" :key="i">
                      <v-flex xs2 text-center pt-2>
                        {{ item.location }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.filmName }}
                      </v-flex>
                      <v-flex xs1 text-center pt-2>
                        {{ formatDate(item.fromDate) }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ formatDate(item.toDate) }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfMembers }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfVehicles }}
                      </v-flex>
                      <v-flex xs1 text-center pt-2>
                        {{ item.shootingDuration }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-4>
          <v-flex xs12 v-if="user === 'range'">
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize bgcustom" @click="getApplication()">
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color:white;font-size: small;"> APPLICATION</span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>


                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize bgcustom" @click="getAcknowledgment()">
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color:white;font-size: small;"> ACKNOWLEDGMENT</span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>


                    <v-flex xs12 lg11 text-center v-if="list.userAgreement" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>

                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              AGREEMENT
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2 v-if="list.license">
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD LICENSE </v-flex>
                        <v-flex xs2>
                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.license"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              LICENSE
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2 v-if="list.letterToRange">
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD LETTER FROM DFO</v-flex>
                        <v-flex xs2>
                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.letterToRange"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              LETTER
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2 v-if="list.proceedings">
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD PROCEEDINGS ORDER</v-flex>
                        <v-flex xs2>
                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.proceedings"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              PROCEEDINGS
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>



                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD MAHASAR</v-flex>
                        <v-flex xs2 v-if="mahassar === 'NO'">
                          <span style="color:green">MAHASAR UPLOADED</span>
                        </v-flex>
                        <v-flex xs2 v-else>
                          <v-btn block class="bgcustom" @click="dialogVisibledummy = true">
                            <v-icon color="white">mdi-upload</v-icon>
                            <span style="font-size: small; color: white;">
                              SELECT MAHASAR
                            </span>
                          </v-btn>
                        </v-flex>&nbsp;&nbsp;&nbsp;
                        <v-flex xs2 v-if="list.tempMahassar">
                          <v-btn block color="blue" dark download :href="baseURL + 'file/get/' + list.tempMahassar"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;
                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              VIEW MAHASAR
                            </span></v-btn>
                        </v-flex>
                        <v-flex xs2 v-if="list.tempMahassar">
                          <v-btn class="mainfont" @click="uploadMahasar()" color="error" :disabled="uploading"><v-icon
                              left>
                              mdi-upload
                            </v-icon>UPLOAD</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <!-- <v-flex xs12 lg11 text-center pa-2>
    <v-layout wrap justify-center>
      <v-flex xs3 text-left> UPLOAD MAHASAR</v-flex>
      <v-flex xs2 text-center v-if="mahasarUploaded">
        <span style="color:green">MAHASAR UPLOADED</span>
      </v-flex>
      <v-flex xs2 text-center v-else>
        <v-btn block class="bgcustom" @click="dialogVisible = true">
          <v-icon color="white">mdi-upload</v-icon>
          <span style="font-size: small; color: white;">MAHASAR</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex> -->
                    <!-- <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD REPORT</v-flex>
                        <v-flex xs2 v-if="report === 'NO'">
                          <span style="color:green">REPORT UPLOADED</span>
                        </v-flex>
                        <v-flex xs2 v-else>

                          <v-btn block class="bgcustom" @click="dialogVisible2 = true">
                            <v-icon color="white">mdi-upload</v-icon>

                            <span style="font-size: small; color: white;">
                              REPORT
                            </span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex> -->

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD REPORT</v-flex>
                        <v-flex xs2 v-if="report === 'NO'">
                          <span style="color:green">REPORT UPLOADED</span>
                        </v-flex>
                        <v-flex xs2 v-else>
                          <v-btn block class="bgcustom" @click="dialogVisible2dummy = true">
                            <v-icon color="white">mdi-upload</v-icon>
                            <span style="font-size: small; color: white;">
                              SELECT REPORT
                            </span>
                          </v-btn>
                        </v-flex>&nbsp;&nbsp;
                        <v-flex xs2 v-if="list.tempReport">
                          <v-btn block color="blue" dark download :href="baseURL + 'file/get/' + list.tempReport"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;
                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              VIEW REPORT
                            </span></v-btn>
                        </v-flex>
                        <v-flex xs2 v-if="list.tempReport">
                          <v-btn class="mainfont" @click="uploadReport()" color="error" :disabled="uploading">
                            <v-icon left>
                              mdi-upload
                            </v-icon>
                            UPLOAD
                          </v-btn>

                        </v-flex>
                      </v-layout>
                    </v-flex>







                    <!-- <v-flex xs12 lg11 text-center pa-2>
    <v-layout wrap justify-center>
      <v-flex xs3 text-left> UPLOAD REPORT</v-flex>
      <v-flex xs2 text-center v-if="reportUploaded">
        <span style="color:green">REPORT UPLOADED</span>
      </v-flex>
      <v-flex xs2 text-center v-else>
        <v-btn block class="bgcustom" @click="dialogVisible2 = true">
          <v-icon color="white">mdi-upload</v-icon>
          <span style="font-size: small; color: white;">REPORT</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex> -->
                    <!-- <v-flex xs12 lg11 text-center v-if="mahassar === 'NO' && report === 'NO'" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> FORWARD TO CLERK</v-flex>
                        <v-flex xs2 text-center>
                          <v-btn block class="text-capitalize" @click="forwardToClerk2()" color="orange">
                            <v-icon color="white">mdi-share</v-icon>&nbsp;
                            <span style="color:white">FORWARD</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <span>Mahasar And Report Upload Successfully.Application will be forwarded to the division office
                        after all the corresponding ranges upload mahasar and report.</span>
                    </v-flex> -->

                    <!-- <v-flex xs12 lg11 text-center pa-2>
    <v-layout wrap justify-center>
      <v-flex v-if="mahassar === 'NO' && report === 'NO' && forwardVariable === 'yes'" xs3 text-left> FORWARD TO CLERK</v-flex>
      <v-flex v-else-if="forwardVariable === 'yes'" xs5 text-center>
        <span style="color:red">Mahasar And Report Upload Successfully.Application will be forwarded to the division office
                        after all the corresponding ranges upload mahasar and report.</span>
      </v-flex>
      <v-flex v-if="mahassar === 'NO' && report === 'NO' && forwardVariable !== 'yes'" xs2 text-center>
        <v-btn block class="text-capitalize" @click="forwardToClerk2()" color="orange">
          <v-icon color="white">mdi-share</v-icon>&nbsp;
          <span style="color:white">FORWARD</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex> -->
                    <v-flex xs12 lg11 text-center v-if="mahassar === 'NO' && report === 'NO'" pa-2>
                      <v-layout wrap justify-center>


                        <v-flex xs2 text-center v-if="isforward === 'yes'">
                          <v-btn block class="text-capitalize" @click="forwardToClerk2()" color="orange">
                            <v-icon color="white">mdi-share</v-icon>&nbsp;
                            <span style="color:white">FORWARD</span>
                          </v-btn>
                        </v-flex>
                        <v-flex xs12 text-center v-else>
                          <span style="color:red">Mahasar And Report Uploaded Successfully.Application will be forwarded
                            to the division office
                            after all the corresponding ranges upload mahasar and report.</span>
                        </v-flex>



                      </v-layout>
                    </v-flex>


                    <!-- <v-flex xs12 lg11 text-center v-if="list.rangeMahassars && list.rangeReports" pa-2>
  <v-layout wrap justify-center>
    <v-flex xs3 text-left v-if="!forwardToClerkClicked"> FORWARD TO CLERK</v-flex>
    <v-flex xs2 text-center v-if="!forwardToClerkClicked">
      <v-btn block class="text-capitalize" @click="forwardToClerk2()" color="orange">
        <v-icon color="white">mdi-share</v-icon>&nbsp;
        <span style="color:white">FORWARD</span>
      </v-btn>
    </v-flex>
    <v-flex xs5 text-center v-if="forwardToClerkClicked">
      <span style="color:red">Mahasar And Report Upload Successfully.Application will be forwarded to the division office after all the corresponding ranges upload mahasar and report.</span>
    </v-flex>
  </v-layout>
</v-flex> -->
                    <!-- <v-flex xs12 lg11 text-center v-if="list.rangeMahassars && list.rangeReports" pa-2>
    <v-layout wrap justify-center>
      <v-flex xs3 text-left v-if="!forwardToClerkClicked"> FORWARD TO CLERK</v-flex>
      <v-flex xs2 text-center v-if="!forwardToClerkClicked">
        <v-btn block class="text-capitalize" @click="forwardToClerk2()" color="orange">
          <v-icon color="white">mdi-share</v-icon>&nbsp;
          <span style="color:white">FORWARD</span>
        </v-btn>
      </v-flex>
      <v-flex xs5 text-center v-if="forwardToClerkClicked">
  <span style="color:red">Mahasar And Report Upload Successfully. Application will be automatically forwarded to the division office after all the corresponding ranges upload mahasar and report.</span>
</v-flex>

    </v-layout>
  </v-flex> -->




                  </v-layout>
                </v-flex>

              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 v-if="user === 'user'">
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex xs12 lg11 text-center v-if="list.cancelRequest === 'No'">
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>CANCEL APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize" @click="(deletedialog = true)" color="red">
                            <v-icon color="white">mdi-cancel</v-icon>
                            <span style="color:white;font-size: small;">CANCEL</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center v-if="list.cancelRequest === 'Yes'">
                      <v-layout wrap justify-center>
                        <v-flex xs5 text-left style="color:red">REQUESTED FOR CANCELLATION</v-flex>
                        <!-- <v-flex xs2>
                          <v-btn block class="text-capitalize" @click="(deletedialog = true)" color="red">
                            <v-icon color="white">mdi-cancel</v-icon>
                            <span style="color:white;font-size: small;">CANCEL</span>
                          </v-btn>
                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize bgcustom" @click="getApplication()">
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color:white;font-size: small;"> APPLICATION</span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize bgcustom" @click="getAcknowledgment()">
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color:white;font-size: small;"> ACKNOWLEDGMENT</span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>

                    <v-flex xs12 lg11 text-center v-if="list.userAgreement" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>

                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              AGREEMENT
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>


                    <v-flex xs12 lg11 text-center v-if="list.paymentInitiation === 'YES'" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>UPLOAD AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn block color="#2e8b57" @click="openDialog = true">
                            <v-icon color="white">mdi-upload</v-icon>

                            <span style="font-size: small;color: white;">
                              AGREEMENT
                            </span>

                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>
                    <v-flex xs12 lg11 text-center v-if="list.paymentInitiation === 'YES'" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize" @click="getAgreement()" color="blue">
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color:white;font-size: small;"> AGREEMENT </span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>


                    <v-flex xs12 lg11 text-center
                      v-if="list.paymentInitiation === 'YES' && list.paymentStatus === 'Pending'" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> FEE PAYMENT</v-flex>
                        <v-flex xs2>
                          <v-btn block color="#2e8b57" @click="feePayment()">
                            <span style="font-size: small;color: white;">
                              Fee Payment
                            </span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>


                    <v-flex xs12 lg11 text-center v-if="list.paymentStatus === 'Success'" pa-2>
                      <v-layout wrap justify-center>

                        <v-flex xs12 lg12 text-center>
                          <span style="color:#228b22;font-weight:bold">Payment Done</span>

                        </v-flex>
                      </v-layout>

                    </v-flex>





                  </v-layout>
                </v-flex>

              </v-layout>
            </v-card>
          </v-flex>

          <v-flex xs12 v-else-if="user === 'section' || user === 'superclerk' || user === 'division'">
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex xs12 lg11 text-center v-if="list.status !== 'Rejected'">
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>CANCEL APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize" @click="(deletedialog = true)" color="red">
                            <v-icon color="white">mdi-cancel</v-icon>
                            <span style="color:white;font-size: small;">CANCEL</span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize bgcustom" @click="getApplication()">
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color:white;font-size: small;"> APPLICATION</span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn block class="text-capitalize bgcustom" @click="getAcknowledgment()">
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color:white;font-size: small;"> ACKNOWLEDGMENT</span>
                          </v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>


                    <v-flex xs12 lg11 text-center v-if="list.userAgreement" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>

                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              AGREEMENT
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>

                    <v-flex xs12 lg11 text-center v-if="list.rangeReport" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD REPORT</v-flex>
                        <v-flex xs2>
                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.rangeReport"
                            target="_blank">
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              REPORT
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>

                    <v-flex xs12 lg11 text-center v-if="list.rangeMahassar" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD MAHASAR</v-flex>
                        <v-flex xs2>
                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.rangeMahassar"
                            target="_blank">
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              MAHASAR
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex>



                  </v-layout>
                </v-flex>

              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs2>
            <v-btn color="primary" @click="goBack">
              <span style="text-transform: none; font-size: 15px; color: white;">
                GO BACK
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- <v-dialog v-model="dialogVisible2" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Report
          </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input class="mainfont" v-model="reportFile" label="Choose Report file"
              :multiple="false"></v-file-input>


          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadReport()" color="primary">Yes</v-btn>
          <v-btn @click="dialogVisible2 = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="dialogVisible2dummy" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Report
          </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input class="mainfont" v-model="reportFile" label="Choose Report file"
              :multiple="false"></v-file-input>


          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadReportdummy()" color="primary">Yes</v-btn>
          <v-btn @click="dialogVisible2dummy = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dialogVisible" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Magasar
          </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input class="mainfont" v-model="mahasarFile" label="Choose Mahasar file"
              :multiple="false"></v-file-input>


          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadMahasar()" color="primary">Yes</v-btn>
          <v-btn @click="dialogVisible = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="dialogVisibledummy" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Mahasar
          </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input class="mainfont" v-model="mahasarFile" label="Choose Mahasar file"
              :multiple="false"></v-file-input>


          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadMahasardummy()" color="primary">Yes</v-btn>
          <v-btn @click="dialogVisibledummy = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Agreement

          </span>
        </v-card-title>
        <v-card-text>
          <v-card>

          </v-card>
          <v-file-input class="mainfont" v-model="file" label="Choose a file"></v-file-input>
        </v-card-text>
        <v-card-actions>

          <v-btn class="mainfont" @click="uploadAgreement()" color="primary">Yes</v-btn>
          <v-btn @click="openDialog = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
      <v-card>
        <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">Are you sure you want
          to Cancel?</v-card-title>
        <v-card-actions class="d-flex justify-center text-center">
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
          <v-btn color="red" class="mainfont" text @click="deleteItem()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
// import CryptoJS from "crypto-js";
export default {
  components: {
  },
  data() {
    // const encryptedId = this.$route.query.id;
    // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
    const today = new Date().toISOString().substr(0, 10);
    return {
      dialogVisible2: false,
      dialogVisible2dummy: false,
      idNo: null,
      startDate: today,
      showSnackBar: false,
      isChecked: false,
      deletedialog: false,
      openDialog: false,
      reportFile: null,
      file: null,
      mahasarFile: null,
      appLoading: false,
      msg: "",
      id: this.$route.query.id,
      // id: decryptedId,
      name: null,
      dialogVisible: false,
      dialogVisibledummy: false,
      barlist: [],
      list: {},
      leaderdetails: [],
      idProof: "",
      Preshootfile: "",
      filmShootingDetails: [],
      forwardToClerkClicked: false,
      mahasarUploaded: false,
      reportUploaded: false,
      mahassar: "",
      report: "",
      savedKey: "",
      savedKey2: "",
    };
  },
  mounted() {
    this.getList();
  },
  created() {
    this.uType = localStorage.getItem("uType");
    // this.mahasarUploaded = localStorage.getItem('mahasarUploaded') === 'true' || false;
    // this.reportUploaded = localStorage.getItem('reportUploaded') === 'true' || false;
    // this.forwardToClerkClicked = sessionStorage.getItem('forwardToClerkClicked') === 'true' || false;
  },

  computed: {
    user() {
      const uType = localStorage.getItem("uType");
      if (uType === "777") {
        return "admin";
      }

      else if (uType === "772") {
        return "user";

      }

      else if (uType === "773") {
        return "range";

      }
      else if (uType === "7741") {
        return "section";
      }
      else if (uType === "7742") {
        return "superclerk";
      }
      else {
        return "division";
      }
    },
  },

  methods: {
    goBack() {
      // Use the router to navigate back to the previous page
      this.$router.go(-1);
    },
    forwardToClerk2() {
      axios({
        url: "/range/forward/clerk",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,

        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            window.alert(response.data.msg);
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            // window.location.reload();
            this.$router.push({ path: "/rangebookings" });

          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //     forwardToClerk2() {
    //   axios({
    //     url: "/range/forward/clerk",
    //     method: "POST",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       appid: this.id,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //         this.deletedialog = false;
    //         this.forwardToClerkClicked = true; // Set local state to true
    //         sessionStorage.setItem('forwardToClerkClicked', 'true'); // Save the state in sessionStorage
    //         this.$router.push({ path: "/rangebookings" });
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },



    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    uploadAgreement() {
      const formData = new FormData();
      formData.append('userAgreement', this.file);
      formData.append('appid', this.id);
      axios.post(`/user/agreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': localStorage.getItem('token'),
        },
      })
        .then(response => {
          console.log(response.data);
          this.openDialog = false;
          location.reload();
        })
        .catch(error => {
          console.error(error);
        });
    },

    uploadReport() {
      if (this.uploading) return;

      this.uploading = true;

      const formData = new FormData();
      formData.append('rangeReports', this.reportFile);
      formData.append('appid', this.id);
      formData.append('key', this.list.tempReport);

      axios.post(`/report/upload`, formData, {
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          console.log(response.data);
          this.dialogVisible2dummy = false;
          window.location.reload();
        })
        .catch(error => {
          // Handle error
          console.error(error);
        })
        .finally(() => {
          // Reset uploading to false to enable the button
          this.uploading = false;
        });
    },
    uploadReportdummy() {
      const formData = new FormData();
      formData.append('tempReport', this.reportFile);
      formData.append('appid', this.id);
      axios.post(`/temp/report`, formData, {
        headers: {

          'token': localStorage.getItem('token'),
        },
        data: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          if (response.data.status == true) {
            this.dialogVisible2dummy = false,
              this.mahasarUploaded = true;
            this.savedKey2 = response.data.key;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(error => {
          // Handle error
          console.error(error);
        });
    },
    // uploadReport() {
    //   const formData = new FormData();
    //   formData.append('rangeReports', this.reportFile);
    //   formData.append('appid', this.id);

    //   axios.post(`/report/upload`, formData, {
    //     headers: {
    //       'token': localStorage.getItem('token'),
    //     },
    //   })
    //   .then(response => {
    //     console.log(response.data);
    //     this.dialogVisible2 = false;
    //     this.reportUploaded = true;
    //     localStorage.setItem('reportUploaded', 'true');
    //     location.reload();
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
    // },
    uploadMahasar() {
      if (this.uploading) return; // Prevent multiple clicks while uploading

      // Set uploading to true to disable the button
      this.uploading = true;

      const formData = new FormData();
      formData.append('rangeMahassars', this.mahasarFile);
      formData.append('appid', this.id);
      formData.append('key', this.list.tempMahassar);

      axios.post(`/mahassar/upload`, formData, {
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          console.log(response.data);
          this.mahasarUploaded = true;
          window.location.reload();
        })
        .catch(error => {
          // Handle error
          console.error(error);
        })
        .finally(() => {
          // Reset uploading to false to enable the button
          this.uploading = false;
        });
    },

    uploadMahasardummy() {
      const formData = new FormData();
      formData.append('tempMahassar', this.mahasarFile);
      formData.append('appid', this.id);
      axios.post(`/temp/mahassar`, formData, {
        headers: {

          'token': localStorage.getItem('token'),
        },
        data: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          if (response.data.status == true) {
            this.dialogVisibledummy = false,
              this.mahasarUploaded = true;
            this.savedKey = response.data.key;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(error => {
          // Handle error
          console.error(error);
        });
    },
    // uploadMahasar() {
    //   const formData = new FormData();
    //   formData.append('rangeMahassars', this.mahasarFile);
    //   formData.append('appid', this.id);

    //   axios.post(`/mahassar/upload`, formData, {
    //     headers: {
    //       'token': localStorage.getItem('token'),
    //     },
    //   })
    //   .then(response => {
    //     console.log(response.data);
    //     this.dialogVisible = false;
    //     this.mahasarUploaded = true; 
    //     localStorage.setItem('mahasarUploaded', 'true'); 
    //   })
    //   .catch(error => {

    //     console.error(error);
    //   });
    // },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      console.log('Selected file:', selectedFile);

    },
    forwardToClerk() {
      axios({
        url: "/check/forward",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    feePayment() {
      axios({
        url: "/fees/submission",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.forwardToClerk();
            // this.deletedialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem() {

      axios({
        url: "/cancel/application",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,

        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getAcknowledgment() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/acknowledgement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getApplication() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/application/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getProceedings() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/proceedings/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getAgreement() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/agreement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },

    // getAgreement() {
    //   const token = localStorage.getItem("token");
    //   const downloadUrl = "/agreement/pdf";
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     params: {
    //       appid: this.id,
    //     },
    //     responseType: "blob",
    //   };

    //   axios
    //     .get(downloadUrl, config)
    //     .then((response) => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' });
    //       const url = window.URL.createObjectURL(blob);

    //       // Set the window location to the PDF URL
    //       window.location.href = url;
    //     })
    //     .catch((error) => {
    //       console.error("Error downloading acknowledgement:", error);
    //     });
    // },


    getList() {
      axios({
        method: "POST",
        url: "/range/single/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.data;
            this.filmShootingDetails = this.list.filmShootingDetails;
            this.memberDetails = this.list.memberDetails;
            this.idProof = this.list.idProof;
            this.Preshootfile = this.list.Preshootfile;
            this.reqLetter = this.list.reqLetter;
            this.participantfile = this.list.participantfile;
            this.leaderIdProof = this.list.leaderIdProof;
            this.mahassar = response.data.mahassar;
            this.report = response.data.report;
            this.forwardvariable = response.data.forwardvariable;
            this.isforward = response.data.isforward;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>


<style>
.subheading {
  font-family: mainfont;
  color: #42275a;
  text-transform: none;
  font-size: 17px;
  font-weight: bold;
}

.heading {
  font-family: mainfont;
  color: white;
  text-transform: none;
  font-size: 22px;

}
</style>